import React, { useContext } from 'react';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import Button from '../components/Buttons';
import { SearchContext } from '../context/SearchContext';
import { UIContext } from '../context/UIContext';
import { SEOInfo } from '../helpers/seo';

const Custom404 = () => {
    const { branch, job, employmentType, workLevel, city, jobOptions, setQuery, removeField } = useContext(SearchContext);
    const { toggleMobileSearch } = useContext(UIContext);

    return (
        <>
            <NextSeo
                title="Sorry, the page you were looking for doesn't exist. (404)"
                description={SEOInfo.description}
                openGraph={{
                    url: SEOInfo.url,
                    title: "Sorry, the page you were looking for doesn't exist. (404)",
                    description: SEOInfo.description,
                    images: [
                        {
                            url: SEOInfo.image,
                        },
                    ],
                    site_name: SEOInfo.siteName,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <div className="max-w-7xl px-4 py-4 bg-gray-50 min-h-screen mx-auto">
                <div className="flex justify-between">
                    <Link href="/">
                        <img className="fill-current h-6 mr-2 cursor-pointer" src="/assets/logo/otellu-logo.svg" alt="Otellu" />
                    </Link>
                    <a href="#">Contact us</a>
                </div>
                <div className="flex flex-col items-center justify-center w-full mt-12">
                    <h1 className="text-3xl text-gray-900">Whoops, that page is gone.</h1>
                    <p className="text-base text-center text-gray-700 max-w-xl mt-4">Something went wrong. Please go back or search for new jobs.</p>
                </div>
                <img
                    className="h-96 w-96 bg-gray-700 mx-auto my-8"
                    src="https://media0.giphy.com/media/14pUUmCj9ysy6A/giphy.gif?cid=ecf05e47jf41zc671mngagof7hdgmy6wxym020v3s36903dw&rid=giphy.gif"
                />
                <div className="">
                    <div className="flex justify-center">
                        <h3 className="text-xl text-gray-800">Search for branches</h3>
                    </div>
                    <div className="grid  grid-cols-2 sm:grid-cols-3 gap-2 mt-4 max-w-4xl mx-auto">
                        <Link href="/s?branch=Sales">
                            <Button text="Sales" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Business/Finance">
                            <Button text="Business/Finance" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Tech">
                            <Button text="Tech" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Marketing">
                            <Button text="Marketing" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Support">
                            <Button text="Support" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Design">
                            <Button text="Design" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Accounting">
                            <Button text="Accounting" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Human Resources">
                            <Button text="Human Resources" theme="btn btn-outline" />
                        </Link>
                        <Link href="/s?branch=Legal">
                            <Button text="Legal" theme="btn btn-outline" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Custom404;
