export const SEOInfo = {
    title: 'Otellu - Find your job at a startup and scale-up',
    description:
        'The job board for start- and scale-ups for finding young urban talent. Match further than just hard skills and find the perfect candidate with our features.',
    image: `${process.env.PEOPLE_WEB_URL}/assets/seo/Seo-banner.jpg`,
    url: 'https://otellu.work',
    siteName: 'Otellu',
};

export const SEOInfoBusiness = {
    title: 'Otellu - Where scaling your team starts',
    description:
        'The job board for start- and scale-ups for finding young urban talent. Match further than just hard skills and find the perfect candidate with our features.',
    image: `${process.env.PEOPLE_WEB_URL}/assets/seo/Seo-banner.jpg`,
    url: 'https://otellu.work',
    siteName: 'Otellu',
};
